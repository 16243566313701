import { render, staticRenderFns } from "./subsidy.vue?vue&type=template&id=16747ec4&scoped=true&"
import script from "./subsidy.vue?vue&type=script&lang=js&"
export * from "./subsidy.vue?vue&type=script&lang=js&"
import style0 from "./subsidy.vue?vue&type=style&index=0&id=16747ec4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16747ec4",
  null
  
)

export default component.exports