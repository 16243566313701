<template>
  <div class="home_warp">
    <!-- 顶部导航 -->
    <titleNav title="技能补贴申请条件" :left-arrow="true" left-text="" />
    <!-- 内容 -->
    <div class="images">
      <img class="imgo" src="@/assets/images/sqtj_bj@2x.png" alt="">
      <img class="imgt" src="@/assets/images/图层 3@2x.png" alt="">
    </div>
    <div class="titles">拓宽技能提升补贴受益范围</div>
    <div class="content">
        领取失业保险金人员取得职业资格证书或职业技能等级证书的，可按照初级(五级)不超过1000元、中级(四级)不超过1500元、高级(三级)不超过2000元的标准申请技能提升补贴。 
    </div>
    <div class="content">
        参保职工取得职业资格证书或职业技能等级证书的，可按规定申请技能提升补贴。
    </div>
    <div class="titles">技能提升补贴申领条件</div>
    <div class="content">
        技能提升补贴申领条件放宽至企业在职职工参加失业保险1年以上。
    </div>
    <div class="content">
        每人每年享受补贴次数最多不超过三次。
    </div>
    <div class="tishi">上述政策执行期限至2022年12月31日</div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
// import floatBtn from "@/components/floatBtn.vue";
import { Button, Dialog, Icon, Toast, Field, Checkbox, CheckboxGroup } from "vant";
import { banner, price, indexs } from "@/network/index.js";
export default {
  components: {
    titleNav,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
        checked: true,
        tel:'',
        sms:'',
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.home_warp {
  height: 100%;
  background: #ffffff;
  background-color: #ffffff;
  position: relative;
  .images{
    width: 100%;
    .imgo{
      width: 100%;
    }
    .imgt{
      width: 50%;
      margin-left: 25%;
    }
  }
  .titles{
    text-align: center;
    font-size: 16px;
    color: #0066CC;
    font-weight: 600;
    line-height: 50px;
  }
  .content{
      padding: 0 10px;
      font-size: 16px;
      text-indent:32px;
  }
  .tishi{
    text-align: center;
    color: #CC0000 ;
    font-size: 15px;
    line-height: 50px;
  }
}
</style>
